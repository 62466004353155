<script setup lang="ts"></script>

<template>
  <div>
    <div class="theme-global">
      <main id="main">
        <slot />
      </main>
    </div>
  </div>
</template>
